import { toast } from "react-toastify";
import expression from "./expression";
import local from "./local";
import api from "./api";
import constant from "./constant";
import moment from "moment";

const helper = {};
helper.config = null;

helper.toast = (type, content) => {
  toast[type](content, { position: "top-right", autoClose: 3000 });
};

helper.convertArrayToObject = (arr, key) => {
  let result = {};
  for (let item of arr) {
    result[item[key]] = item;
  }
  return result;
};

helper.expressionParse = (exp, source) => {
  try {
    return expression.parse(exp).evaluate(source);
  } catch (error) {
    return null;
  }
};

helper.convertObject = (inputObject, parentKeys = []) => {
  let result = {};

  for (let key in inputObject) {
    const currentKeys = [...parentKeys, key];
    if (key === "documents") {
      result.documents = inputObject[key];
    } else if (Array.isArray(inputObject[key])) {
      const newKey = currentKeys.join("_");
      result[newKey] = inputObject[key];
      continue;
    } else if (typeof inputObject[key] === "object") {
      result = {
        ...result,
        ...helper.convertObject(inputObject[key], currentKeys),
      };
    } else {
      const newKey = currentKeys.join("_");
      result[newKey] = inputObject[key];
    }
  }

  return result;
};

helper.stringToCurrency = (str) => {
  if (!str && str !== 0) return "";

  let rawStr = str.toString(),
    amt = "";
  const raw = rawStr.split(".")[0];
  const decimal = rawStr.split(".")[1];
  for (let i = raw.length - 1, j = 1; i >= 0; i--, j++) {
    amt = raw[i] + amt;
    if (j % 3 === 0 && i != 0) {
      amt = "," + amt;
    }
  }
  if (decimal) {
    return `${amt}.${decimal} đ`;
  } else {
    return `${amt} đ`;
  }
};
helper.stringToDate = (str) => {
  if (!str) return "";

  const date = new Date(str);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

helper.getPendingTask = async (body = {}) => {
  let result = await api.transaction({
    serviceId: helper.config?.["SEGETPENDINGTASK"]?.id,
    flowId: local.getItem("flowId"),
    phone: local.getItem("phone"),
    nric: local.getItem("nric"),
    applicantId: local.getItem("applicantId"),
    name: "SEGETPENDINGTASK",
    ...body,
  });
  if (
    result.err === 200 &&
    result.data.errorCode === 0 &&
    result.data.currentStep
  ) {
    if (result.data.application) {
      local.setItem("applicationInfo", result.data.application);
    }
    return [result.data.currentStep, result];
  }
  return [null, null];
};

helper.getPendingTaskFirstTime = async (body = {}) => {
  let result = await api.transaction({
    serviceId: helper.config?.["SEGETPENDINGTASKFIRSTTIME"]?.id,
    flowId: local.getItem("flowId"),
    phone: local.getItem("phone"),
    nric: local.getItem("nric"),
    applicantId: local.getItem("applicantId"),
    name:"SEGETPENDINGTASKFIRSTTIME",
    ...body,
  });
  if (
    result.err === 200 &&
    result.data.errorCode === 0 &&
    result.data.currentStep
  ) {
    if (result.data.application) {
      local.setItem("applicationInfo", result.data.application);
    }
    return [result.data.currentStep, result];
  }
  return [null, null];
};

helper.identityType = (value = "cccd") => {
  return constant.IDENTITY_TYPE[value?.toUpperCase()];
};
helper.booleanToString = (value) => {
  return value.toString();
};
helper.compareString = (valueCompare, value) => {
  if (valueCompare === value) {
    return (
      <span style={{ color: "#2f6bff" }}>
        Tên tài khoản trùng khớp với người vay
      </span>
    );
  }
  return (
    <span style={{ color: "#e7252b" }}>
      Tên tài khoản không trùng khớp với người vay
    </span>
  );
};
helper.calcDateToNow = (value) => {
  const now = moment(new Date());
  const pastDate = moment(value);
  let years = now.diff(pastDate, "years");
  let months = now.diff(pastDate, "months") - years * 12;
  return `${helper.stringToDate(value)} (${years} năm ${months} tháng )`;
};
helper.changeLanguage = (key, value) => {
  if (!value || !key) return "";
  return DATA[key][value];
};

export default helper;

const DATA = {
  INPUT_METHOD: {
    ocr: "OCR",
    nfc: "NFC",
    manual: "MANUAL",
  },
  GENDER: {
    male: "Nam",
    female: "Nữ",
    others: "Khác",
  },
  WORK_TYPE: {
    fulltime: "Toàn thời gian",
    season: "Thời vụ",
    parttime: "Bán thời gian",
    others: "Khác",
  },
  REPAYMENT_METHOD: {
    autoDebit: "Trích nợ tự động",
    others: "Thanh toán bằng phương thức khác",
  },
  DISBURSEMENT_OBJECT: {
    customer: "Giải ngân cho khách hàng",
    partner: "Giải ngân cho bên thứ ba",
  },
  PAYMENT_ADDRESS: {
    vnpost: "Chi hộ qua Bưu điện Việt Nam",
    vietinbank: "Chi hộ qua Vietinbank",
  },
  UPLOAD_METHOD: {
    library: "Chọn ảnh từ thư viện",
    capture: "Chụp ảnh trực tiếp",
  },
  DISBURSEMENT_METHOD: {
    cash: "Tiền mặt",
    transfer: "Chuyển khoản",
  },
  ACADEMIC_LEVEL: {
    primary: "Tiểu học",
    secondary: "THCS",
    highSchool: "THPT",
    vocational: "Trung cấp",
    college: "Cao đẳng",
    bachelor: "Đại học",
    postGraduate: "Sau đại học",
  },
  MARITAL_STATUS: {
    single: "Độc thân",
    married: "Kết hôn",
    divorced: "Ly hôn",
    others: "Khác",
  },
  RELATIONSHIP: {
    parents: "Bố/Mẹ",
    siblings: "Anh/Chị/Em/Ruột",
    relatives: "Họ hàng",
    child: "Con",
  },
  BOOLEAN_VALUE: {
    true: "Đồng ý",
    false: "Không đồng ý",
  },
  PAYMENT_POINT: {
    vnpost: "Chi hộ qua Bưu điện Việt Nam",
    vietinbank: "Chi hộ qua Vietinbank",
  },
  TIME_CONTRACT: {
    UnderAYear: "Dưới 1 năm",
    Under3Year: "Từ 1-3 năm",
    Over3Year: "Trên 3 năm",
    Indefinite: "Không xác định thời hạn",
  },
};
