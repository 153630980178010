import { constant, helper } from "services";

export const APPLICATION_ITEMS = [
  {
    label: "Thông tin khoản vay",
    cells: [
      {
        label: "Business:",
        field: "product_business_name",
      },
      {
        label: "Mã scheme:",
        field: "product_scheme_code",
      },
      {
        label: "Tên scheme:",
        field: "product_scheme_name",
      },
      {
        label: "Số tiền vay:",
        field: "requestAmount",
        render: (values, value) => helper.stringToCurrency(value),
      },
      {
        label: "Thời gian vay:",
        field: "loanTerm",
        render: (values, value) => `${value} tháng`,
      },
      {
        label: "Lãi suất:",
        field: "product_scheme_interest_interest_one_value",
        render: (values, value) => {
          const fields = ["product_scheme_interest_interest_one_value", "product_scheme_interest_interest_two_value", "product_scheme_interest_interest_three_value", "product_scheme_interest_interest_four_value", "product_scheme_interest_interest_five_value"]
          let num = values.product_scheme_interest_numOfInterests
          return values[fields?.[num - 1]] + ' %'
        },
      },
      {
        label: "Ngày tạo hồ sơ:",
        field: "createdAt",
        render: (values, value) => helper.stringToDate(value),
      },
      {
        label: "Mã nộp hồ sơ:",
        field: "applicantId",
        style: {
          color: "rgba(47, 107, 255, 1)",
        },
      },
      {
        label: "Trạng thái:",
        field: "status",
        style: {
          color: "rgba(245, 130, 32, 1)",
          fontWeight: "bold",
        },
        render: (values, value) => "Chờ xác nhận",
      },
    ],
  },
  {
    label: "Thông tin khách hàng",
    cells: [
      // {
      //   label: "Loại giấy tờ:",
      //   field: "customer_nricType",
      //   render: (values, value) => helper.identityType(value),
      // },
      {
        label: "Họ và tên:",
        field: "customer_name",
      },
      // {
      //   label: "Ngày sinh:",
      //   field: "customer_demographic_dob",
      //   render: (values, value) => helper.stringToDate(value),
      // },
      // {
      //   label: "Giới tính:",
      //   field: "customer_gender",
      //   render: (values, value) => (value === "male" ? "Nam" : "Nữ"),
      // },
      {
        label: "Số CCCD:",
        field: "customer_nric",
      },
      {
        label: "Số CMND cũ:",
        field: "customer_oldNric",
      },
      // {
      //   label: "Ngày cấp:",
      //   field: "customer_nricDate",
      //   render: (values, value) => helper.stringToDate(value),
      // },
      // {
      //   label: "Nơi cấp:",
      //   field: "customer_nricIssuer",
      // },
    ],
  },
  // {
  //   label: "Thông tin khác",
  //   cells: [
  //     {
  //       label: "Số CMND cũ:",
  //       field: "customer_oldNric",
  //     },
  //     {
  //       label: "Trình độ học vấn:",
  //       field: "customer_demographic_academicLevel",
  //     },
  //     {
  //       label: "Tình trạng hôn nhân:",
  //       field: "customer_demographic_maritalStatus",
  //     },
  //     {
  //       label: "Họ và tên vợ/chồng:",
  //       field: "coborrower_name",
  //     },
  //     {
  //       label: "Số CCCD/Hộ chiếu:",
  //       field: "customer_nric",
  //     },
  //     {
  //       label: "Số điện thoại:",
  //       field: "customer_phone",
  //     },
  //     {
  //       label: "Người phụ thuộc:",
  //       field: "",
  //     },
  //   ],
  // },
];

export const ADDITION_NRICS = (data) => {
  let identityType = constant.IDENTITY_TYPE, identities = {}
  for (let type in identityType) {
    identities[type] = 0
  }
  identities.CCCD = identities.CCCD + 1
  return data.map((item, index) => {
    let typ = item.identityType.toUpperCase()
    identities[typ] = identities[typ] + 1
    return {
      ...item,
      label: constant.IDENTITY[typ] + ' ' + identities[typ] + ":",
      value: item.identityId
    }
  })
}

export const DETAILS = {
  information: {
    label: "1. Thông tin cá nhân",
    fields: [
      {
        label: "Loại giấy tờ:",
        field: "customer_nricType",
        render: (values, value) => helper.identityType(value),
      },
      {
        label: "Họ và tên:",
        field: "customer_name",
      },
      {
        label: "Ngày sinh:",
        field: "customer_demographic_dob",
        render: (values, value) => helper.stringToDate(value),
      },
      {
        label: "Giới tính:",
        field: "customer_gender",
        render: (values, value) => helper.changeLanguage("GENDER", value),
      },
      {
        label: "Quốc tịch:",
        field: "customer_demographic_nationality",
      },
      {
        label: "Địa chỉ thường trú:",
        field: "customer_homeContact_address",
      },
      {
        label: "Số CCCD:",
        field: "customer_nric",
      },
      {
        label: "Ngày cấp:",
        field: "customer_nricDate",
        render: (values, value) => helper.stringToDate(value),
      },
      {
        label: "Ngày hết hạn:",
        field: "customer_nricExpiry",
        render: (values, value) => helper.stringToDate(value),
      },
      {
        label: "Nơi cấp:",
        field: "customer_nricIssuer",
      },
      {
        label: "Số CMT cũ:",
        field: "customer_oldNric",
      },
      {
        label: "Số điện thoại:",
        field: "customer_phone",
      },
      {
        label: "Email:",
        field: "customer_email",
      },
      {
        label: "Trình độ học vấn:",
        field: "customer_demographic_academicLevel",
        // render: (values, value) =>
        //   helper.changeLanguage("ACADEMIC_LEVEL", value),
      },
      {
        label: "Tình trạng hôn nhân:",
        field: "customer_demographic_maritalStatus",
        render: (values, value) =>
          helper.changeLanguage("MARITAL_STATUS", value),
      },
    ],
  },
  residence: {
    label: "2. Thông tin cư trú:",
    fields: [
      // {
      //   className: "w-full text-left",
      //   render: () => (
      //     <div className="flex items-center">
      //       <input
      //         type="checkbox"
      //         className="form-checkbox h-4 w-4 rounded"
      //         onChange={() => {}}
      //       />
      //       <span className="ml-2">Trùng với địa chỉ trên Giấy tờ chính</span>
      //     </div>
      //   ),
      // },
      {
        label: "Tỉnh/thành phố:",
        field: "customer_mailingContact_province",
      },
      {
        label: "Quận/huyện:",
        field: "customer_mailingContact_district",
      },
      {
        label: "Phường xã:",
        field: "customer_mailingContact_ward",
      },
      {
        label: "Địa chỉ cụ thể:",
        field: "customer_mailingContact_address",
      },
      {
        label: "Thời gian bắt đầu cư trú:",
        field: "customer_mailingContact_residenceStartTime",
        render: (values, value) => helper.stringToDate(value),
      },
      {
        label: "Tình trạng cư trú:",
        field: "customer_mailingContact_residenceStatus",
      },
    ],
    cells: [
      {
        label: "Thông tin sổ hộ khẩu",
        fields: [
          // {
          //   className: "w-full text-left",
          //   render: () => (
          //     <div className="flex items-center">
          //       <input
          //         type="checkbox"
          //         className="form-checkbox h-4 w-4 rounded"
          //       />
          //       <span className="ml-2">Trùng với nơi ở hiện tại</span>
          //     </div>
          //   ),
          // },
          {
            label: "Tỉnh/thành phố:",
            field: "customer_mailingContact_province",
          },
          {
            label: "Quận/Huyện:",
            field: "customer_mailingContact_district",
          },
          {
            label: "Phường xã:",
            field: "customer_mailingContact_ward",
          },
          {
            label: "Địa chỉ cụ thể:",
            field: "customer_homeContact_address",
          },
        ],
      },
    ],
  },
  job: {
    label: "3. Thông tin việc làm",
    fields: [
      {
        label: "Nghề nghiệp:",
        field: "customer_demographic_occupation",
      },
      {
        label: "Thu nhập hàng tháng:",
        field: "customer_financial_monthlyIncome",
        render: (values, value) => helper.stringToCurrency(value),
      },
      {
        label: "Chi phí cá nhân:",
        field: "customer_financial_personalCosts",
        render: (values, value) => helper.stringToCurrency(value),
      },
      {
        label: "Hình thức nhận lương:",
        field: "customer_financial_payForms",
        render: (values, value) =>
          helper.changeLanguage("DISBURSEMENT_METHOD", value),
      },
      {
        label: "Thời gian bắt đầu làm việc:",
        field: "customer_workingContact_workingStartDate",
        render: (values, value) => helper.calcDateToNow(value),
      },
      {
        label: "Loại hình công việc",
        field: "customer_demographic_typeOfWork",
        render: (values, value) => helper.changeLanguage("WORK_TYPE", value),
      },
    ],
    cells: [
      {
        label: "Thông tin nơi làm việc",
        fields: [
          {
            label: "Tên cơ quan/nơi làm việc:",
            field: "customer_workingContact_agency",
          },
          {
            label: "Mã số thuế:",
            field: "customer_workingContact_taxCode",
          },
          {
            label: "Số điện thoại nơi làm việc:",
            field: "customer_workingContact_phone",
          },
          {
            label: "Thời gian hợp đồng:",
            field: "customer_workingContact_contractPeriod",
            // render: (values, value) => helper.changeLanguage("TIME_CONTRACT", value),
          },
        ],
      },
      {
        label: "Địa chỉ làm việc",
        fields: [
          {
            label: "Tỉnh/thành phố:",
            field: "customer_workingContact_province",
          },
          {
            label: "Quận/Huyện:",
            field: "customer_workingContact_distrist",
          },
          {
            label: "Phường xã:",
            field: "customer_workingContact_ward",
          },
          {
            label: "Địa chỉ cụ thể:",
            field: "customer_workingContact_address",
          },
        ],
      },
    ],
  },
  reference: {
    label: "4. Thông tin tham chiếu:",
    cells: [
      {
        label: "Người tham chiếu 1",
        fields: [
          {
            label: "Họ và tên:",
            field: "reference_referenceName",
          },
          {
            label: "Số giấy tờ tùy thân:",
            field: "reference_identity",
          },
          {
            label: "Mối quan hệ:",
            field: "reference_relationship",
            render: (values, value) =>
              helper.changeLanguage("RELATIONSHIP", value),
          },
          {
            label: "Số điện thoại:",
            field: "reference_referencePhone",
          },
          {
            label: "Tỉnh/thành phố:",
            field: "reference_province",
          },
          {
            label: "Quận/Huyện:",
            field: "reference_district",
          },
          {
            label: "Phường xã:",
            field: "reference_ward",
          },
          {
            label: "Địa chỉ cụ thể:",
            field: "reference_address",
          },
        ],
      },
      {
        label: "Người tham chiếu 2",
        condition: "reference2_identity",
        fields: [
          {
            label: "Họ và tên:",
            field: "reference2_name",
          },
          {
            label: "Số giấy tờ tùy thân:",
            field: "reference2_identity",
          },
          {
            label: "Số điện thoại:",
            field: "reference2_phone",
          },
          {
            label: "Mối quan hệ:",
            field: "reference2_relationship",
            render: (values, value) =>
              helper.changeLanguage("RELATIONSHIP", value),
          },
        ],
      },
    ],
  },
  disbursement: {
    label: "5. Thông tin thanh toán và giải ngân",
    cells: [
      {
        label: "Thông tin giải ngân",
        condition: "disbursement_method == 'transfer'",
        fields: [
          {
            label: "Hình thức nhận tiền",
            field: "disbursement_method",
            render: (values, value) =>
              helper.changeLanguage("DISBURSEMENT_METHOD", value),
          },
          {
            label: "Đối tượng giải ngân:",
            field: "disbursement_bankAccountName",
          },
          {
            label: "Ngân hàng:",
            field: "disbursement_bankCode",
          },
          {
            label: "Sô tài khoản:",
            field: "disbursement_bankAccountNumber",
          },
          {
            label: "Chi nhánh:",
            field: "disbursement_bankBranchCode",
          },

          {
            field: "repayment_bankAccountName",
            compare: "customer_name",
            className: "w-full text-left",
            function: true,
            render: (values, value) => (
              <div
                style={{
                  backgroundColor: "#f4f8ff",
                  borderRadius: 8,
                  padding: 8,
                }}
              >
                <p className="font-medium">Chủ tài khoản</p>
                <p className="font-medium leading-6"> {value}</p>
                {helper.compareString(values, value)}
              </div>
            ),
          },
        ],
      },
      {
        label: "Thông tin giải ngân",
        condition: "disbursement_method == 'cash'",
        fields: [
          {
            label: "Hình thức nhận tiền:",
            field: "disbursement_method",
            render: (values, value) =>
              helper.changeLanguage("DISBURSEMENT_METHOD", value),
          },
          {
            label: "Điểm chi hộ",
            field: "disbursement_paymentPoint",
            render: (values, value) =>
              helper.changeLanguage("PAYMENT_POINT", value),
          },
        ],
      },
      {
        label: "Thông tin thanh toán",
        // condition: "disbursement_method == 'transfer'",
        fields: [
          {
            label: "Chọn phương thức trả nợ:",
            field: "repayment_method",
            render: (values, value) =>
              helper.changeLanguage("REPAYMENT_METHOD", value),
          },
          {
            label: "Ngân hàng:",
            field: "repayment_bankCode",
          },
          {
            label: "Số tài khoản:",
            field: "repayment_bankAccountNumber",
          },
          {
            label: "Tên tài khoản:",
            field: "repayment_bankAccountName",
          },
          {
            label: "Chi nhánh:",
            field: "repayment_bankBranchCode",
          },
          {
            label: "Đồng ý trích nợ tự động:",
            field: "repayment_autoRepayment",
            render: (values, value) =>
              helper.changeLanguage("BOOLEAN_VALUE", value),
          },
        ],
      },
    ],
  },
  upload: {
    label: "6. Hồ sơ khoản vay",
    fields: [
      {
        label: "Hóa đơn điện nước",
        field: "documents_hoaDonDienNuoc",
      },
      {
        label: "Thẻ bảo hiểm y tế",
        field: "",
      },
      {
        label: "Chứng minh thu nhập",
        field: "",
      },
      {
        label: "Chứng nhận kết hôn",
        field: "",
      },
    ],
  },
};
